<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon v-if="params.select" icon="CheckSquareIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="select" />
      <feather-icon v-if="params.print" icon="PrinterIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="print" />
      <feather-icon v-if="params.edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="edit" />
      <feather-icon v-if="params.delete" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="deleteRecord" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
    edit () {
      this.params.edit(this.params)// assim chamo a função de fora
    },
    deleteRecord () {
      this.params.delete(this.params)// assim chamo a função de fora
    },
    print () {
      this.params.print(this.params)// assim chamo a função de fora
    },
    select () {
      this.params.select(this.params)// assim chamo a função de fora
    }
  }
}
</script>
