var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { direction: _vm.$vs.rtl ? "rtl" : "ltr" } },
    [
      _vm.params.select
        ? _c("feather-icon", {
            attrs: {
              icon: "CheckSquareIcon",
              svgClasses: "h-5 w-5 hover:text-danger cursor-pointer",
            },
            on: { click: _vm.select },
          })
        : _vm._e(),
      _vm.params.print
        ? _c("feather-icon", {
            attrs: {
              icon: "PrinterIcon",
              svgClasses: "h-5 w-5 hover:text-danger cursor-pointer",
            },
            on: { click: _vm.print },
          })
        : _vm._e(),
      _vm.params.edit
        ? _c("feather-icon", {
            attrs: {
              icon: "Edit3Icon",
              svgClasses: "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
            },
            on: { click: _vm.edit },
          })
        : _vm._e(),
      _vm.params.delete
        ? _c("feather-icon", {
            attrs: {
              icon: "Trash2Icon",
              svgClasses: "h-5 w-5 hover:text-danger cursor-pointer",
            },
            on: { click: _vm.deleteRecord },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }