<template>
  <div class="vx-row w-full flex">
    <div class="flex w-full">
      <label class="vs-input--label w-full"
        >{{ model.$select.label }}
        {{ disabled ? ' - Carregando...' : '' }}</label
      >
    </div>
    <div class="w-full flex">
      <vs-button
        v-if="selected[ind] && selected[ind].id"
        class="w-full"
        type="border"
        @click="open"
        :disabled="disabled"
      >
        {{ indexSelect !== undefined ? indexSelect + 1 : '' }}
        {{ selected[ind].$title() }}
      </vs-button>
      <vs-button
        v-else
        class="w-full"
        color="danger"
        type="border"
        @click="open"
        :disabled="disabled"
      >
        {{ indexSelect !== undefined ? indexSelect + 1 : '' }}
        {{ model.$select.name }}
      </vs-button>
    </div>
    <vs-popup :active.sync="isOpen" fullscreen :title="model.$select.label">
      <slot></slot>
    </vs-popup>
  </div>
</template>

<script>
export default {
  props: {
    model: {},
    nameSelect: {
      required: true
    },
    indexSelect: {},
    enabled: {
      default: true
    }
  }, // index é o indice do objeto selecionado (state.select.selected)
  data () {
    return {
      ind: this.indexSelect || 0,
      closed: false,
      manual: true
    }
  },
  watch: {
    popup (value) {
      if (value) {
        const me = this
        this.$store.commit('popup/add', {
          index: this.indexSelect || 0,
          open () {
            me.isOpen = true
          },
          close () {
            me.isOpen = null
            me.popup = null
          }
        })
      } else if (value === false) {
        this.$store.commit('popup/remove')
      }
    }
  },
  computed: {
    isOpen: {
      get () {
        return this.$store.state[this.model.$store.name].select[this.nameSelect]
          .isOpen
      },
      set (value) {
        if (value === false) {
          this.popup = false
        }

        this.$store.commit(this.model.$store.commit.closeSelect, {
          isOpen: value || false
        })
      }
    },
    popup: {
      get () {
        return this.$store.state[this.model.$store.name].select[this.nameSelect]
          .popup
      },
      set (value) {
        this.$store.commit(this.model.$store.commit.closeSelect, {
          popup: value
        })
      }
    },
    index: {
      get () {
        return this.$store.state[this.model.$store.name].select.index
      },
      set (value) {
        this.$store.commit(this.model.$store.commit.select, {
          index: value
        })
      }
    },
    name: {
      get () {
        return this.$store.state[this.model.$store.name].select.name
      },
      set (value) {
        this.$store.commit(this.model.$store.commit.select, {
          name: value
        })
      }
    },
    disabled: {
      get () {
        return this.$store.state[this.model.$store.name].select[this.nameSelect]
          .disabled
      },
      set (payload) {
        this.$store.commit(this.model.$store.commit.select, {
          name: this.nameSelect,
          disabled: payload
        })
      }
    },
    selected () {
      return this.$store.state[this.model.$store.name].select[this.nameSelect]
        .selected
    }
  },
  methods: {
    open () {
      this.$store.commit(this.model.$store.commit.nameSelect, this.nameSelect)
      this.$emit('open-sigselect')
      this.popup = true
      this.index = this.indexSelect
    },
    // fechar lista de ativar formulario
    close () {
      //this.isOpen = false
    }
  }
}
</script>
