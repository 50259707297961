<template>
  <div id="page-account-plans">
    <div class="flex flex-wrap items-center">
      <div class="flex">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="
              p-4
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ currentPage * paginationPageSize - (paginationPageSize - 1) }}
              -
              {{
                fetchGrid.total - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : fetchGrid.total
              }}
              de {{ fetchGrid.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="paginationSetPageSize(10)">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="paginationSetPageSize(20)">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="paginationSetPageSize(30)">
              <span>30</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <div class="flex-2">
        <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />
      </div>
      <div class="flex-2">
        <vs-input
          class="
            sm:mr-4
            mr-0
            sm:w-auto
            w-full
            sm:order-normal
            order-3
            sm:mt-0
            mt-4
          "
          v-model="searchQuery"
          @input="updateSearchQuery"
          placeholder="Busca rápida por nome..."
        />
      </div>
      <div class="flex-2" v-if="!model.$form.disabled">
        <vs-button
          @click="register(false)"
          icon-pack="feather"
          class="shadow-md mb-4 md:mb-0"
          >Cadastrar</vs-button
        >
      </div>
    </div>
    <ag-grid-vue
      ref="agGridTable"
      :components="components"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      :overlayNoRowsTemplate="noRowsTemplate"
      :localeText="localeText"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :cacheBlockSize="payload.limit"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl"
    >
    </ag-grid-vue>
    <slot
      name="form"
      ref="form"
    ></slot>
  </div>
</template>

<script>
import _ from 'lodash'
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererActions from '@/components/cell-renderer/CellRendererActions.vue'
import { localePt_Br } from '@/assets/agGridTranslate/locale.pt_Br'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    AgGridVue,
    CellRendererActions
  },
  props: [
    'model',
    'allowSearch',
    'formComponent',
    'print',
    'propEdit'
  ],
  data () {
    const columns = this.model.$grid.columns
    const me = this
    if (this.model.$grid.actions && columns[0] && columns[0].field !== 'actions') {
      columns.unshift({
        headerName: 'Ações',
        field: 'actions',
        width: 95,
        sortable: false,
        cellRendererFramework: 'CellRendererActions',
        cellRendererParams: {
          delete: this.model.$grid.actions.delete ? me.confirmDelete : false,
          edit: this.model.$grid.actions.edit ? me.propEdit || me.edit : false,
          print: this.model.$grid.actions.print ? me.print : false,
          select: this.model.$grid.actions.select ? me.select : false
        }
      })
    }
    return {
      dataSource: {},
      localeText: null,
      autoSearch: this.allowSearch,
      gridApi: null,
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      components: {
        CellRendererActions
      },
      searchQuery: '',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      gridOptions: {
        rowModelType: 'infinite',
        tooltipShowDelay: 500
        // onCellClicked: this.onCellClicked
      },
      columnDefs: columns,
      registro: null
    }
  },
  watch: {
    totalGrid () {
      this.gridApi.setDatasource(this.dataSource)
    }
  },
  computed: {
    nameSelect () {
      return this.$store.state[this.model.$store.name].select.name
    },
    indexSelect () {
      return this.$store.state[this.model.$store.name].select.index
    },
    activeForm: {
      get () {
        return this.$store.state[this.model.$store.name].form.popup
      },
      set (value) {
        this.$store.commit(this.model.$store.commit.activeForm, {
          popup: value
        })
      }
    },
    fetchGrid () {
      return this.$store.state[this.model.$store.name].fetchGrid
    },
    totalGrid () {
      return this.$store.state[this.model.$store.name].fetchGrid.total
    }
  },
  methods: {
    select ({ data }, manual = true) {
      // se estiver no formulario
      if (manual) {
        this.$store.commit('popup/remove')
      }
      if (this.nameSelect) {
        this.$store.commit(this.model.$store.commit.selected, {
          name: this.nameSelect,
          data,
          index: this.indexSelect || 0 // indice do objeto state.selected
        })
        this.$emit('selected', data)
      }
    },
    register (data) {
      this.$store.commit(this.model.$store.commit.single, data)
      this.activeForm = true
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    onGridReady (params) {
      this.gridApi = params.api
      const self = this
      this.$emit('grid-ready')
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.autoSearch && self.payload.consultApi) await self.search()
          self.payload.consultApi = true
          let lastRow = -1
          if (self.fetchGrid.total <= params.endRow) {
            lastRow = self.fetchGrid.total
          }
          params.successCallback(self.fetchGrid.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },
    async search (clearSelected = true) {
      this.gridApi.showLoadingOverlay()
      const me = this
      const data = await this.$store
        .dispatch(this.model.$store.dispatch.fetchGrid, this.payload)
        .finally(() => {
          me.gridApi.hideOverlay()
          this.$emit('search-end')
        })

      if (!data.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      if (data.data.total === 1) {
        if (clearSelected) {
          me.select({
            data: data.data.data[0]
          }, false)
        }
      } else if (clearSelected) {
        me.select({
          data: undefined
        }, false)
      }
      return data
    },
    async searchUpdate (clear) {
      await this.search(clear)
      this.getSearchResult()
    },
    confirmDelete (data) {
      this.$store.commit(this.model.$store.commit.delete, data.data)
      this.mixinConfirmDialog(this.delete)
    },
    delete () {
      this.$store
        .dispatch(this.model.$store.dispatch.delete)
        .then(this.mixinThen(this.searchUpdate))
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    },
    edit ({data}) {
      this.$emit('click-edit')
      data._editing = true // preciso informar ao formulario que é uma edição
      this.register(data)
    },
    refreshGrid (consultApi = true) {
      this.payload.consultApi = consultApi
      this.gridApi.setDatasource(this.dataSource)
    },
    getSearchResult (searchVal) {
      this.payload.searchQuery = searchVal
      this.gridApi.setDatasource(this.dataSource)
    },

    refresh () {
      // habilito busca
      this.autoSearch = true
      if (!this.totalGrid) {
        this.refreshGrid()
      }
    },
    filter (filter) {
      this.payload.filter = filter
    }
  },
  mounted () {
    this.localeText = localePt_Br
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        '.ag-header-container'
      )
      header.style.left = `-${String(
        Number(header.style.transform.slice(11, -3)) + 9
      )}px`
    }
  },
  created () {
    this.setGrid('fetchGrid')
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
  }
}
</script>
