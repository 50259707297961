var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vx-row w-full flex" },
    [
      _c("div", { staticClass: "flex w-full" }, [
        _c("label", { staticClass: "vs-input--label w-full" }, [
          _vm._v(
            _vm._s(_vm.model.$select.label) +
              "\n      " +
              _vm._s(_vm.disabled ? " - Carregando..." : "")
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "w-full flex" },
        [
          _vm.selected[_vm.ind] && _vm.selected[_vm.ind].id
            ? _c(
                "vs-button",
                {
                  staticClass: "w-full",
                  attrs: { type: "border", disabled: _vm.disabled },
                  on: { click: _vm.open },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.indexSelect !== undefined ? _vm.indexSelect + 1 : ""
                      ) +
                      "\n      " +
                      _vm._s(_vm.selected[_vm.ind].$title()) +
                      "\n    "
                  ),
                ]
              )
            : _c(
                "vs-button",
                {
                  staticClass: "w-full",
                  attrs: {
                    color: "danger",
                    type: "border",
                    disabled: _vm.disabled,
                  },
                  on: { click: _vm.open },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.indexSelect !== undefined ? _vm.indexSelect + 1 : ""
                      ) +
                      "\n      " +
                      _vm._s(_vm.model.$select.name) +
                      "\n    "
                  ),
                ]
              ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            active: _vm.isOpen,
            fullscreen: "",
            title: _vm.model.$select.label,
          },
          on: {
            "update:active": function ($event) {
              _vm.isOpen = $event
            },
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }